import React                from 'react'
import { navigate }         from 'gatsby'
import { map }              from 'lodash-es'
import cx                   from 'utils/classnames'
import {
    FaClock, FaCcVisa, FaCcMastercard, FaArrowLeft,
}                           from 'react-icons/fa'
import {
    Button,
    Container,
    Col,
    Row
}                           from 'react-bootstrap'
import {
    StickyContainer,
    Sticky
}                           from 'react-sticky'
import Icon                 from 'entrada-ui/Icon'
import Typography           from 'entrada-ui/Typography'
import Link                 from 'components/Link'
// import SEO                  from 'components/Seo'
import BookContact          from 'components/BookContact'
import CartHandler          from 'components/CartHandler'
import CartItem             from 'components/Checkout/CartItem'
import CheckoutTerms        from 'components/Checkout/CheckoutTerms'
import ContinueCheckout     from 'components/Checkout/ContinueCheckout'
import Guarantee            from 'components/Checkout/Guarantee'
import TourCards            from 'components/TourCards'
import styles               from './styles.module.scss'
import { isJwtExpired }     from 'utils'

const TourCard = TourCards.CardWp

let prevScroll = 0

const CartTemplate = ({
    pageContext: {
        data: {
            acf: {
                promoted_tours
            }
        },
        destinationsUrl
    }
}) => {
    const ref = React.useRef(null)
    const [headerOffsetTop, setHeaderOffsetTop] = React.useState(0)
    const [scrollingUp, setScrollingUp] = React.useState(false)

    const context = React.useContext(CartHandler.Context)
    const { apiToken, cartItems, resetCart, setSessionTokenExpired } = context

    React.useEffect(() => {
        if(!apiToken) {
            resetCart()
        } else if (isJwtExpired(apiToken)) {
            setSessionTokenExpired(true)
        }
    }, [apiToken, cartItems.length])

    React.useEffect(() => {
        const handleScrollWindow = (ev) => {
            const newScroll = window.pageYOffset
            setScrollingUp(prevScroll > newScroll)

            prevScroll = newScroll
        }

        window.addEventListener('scroll', handleScrollWindow)

        return () => window.removeEventListener('scroll', handleScrollWindow)
    }, [headerOffsetTop])


    React.useEffect(() => {
        if(ref) {
            setHeaderOffsetTop(ref.current.getBoundingClientRect().top)
        }
    }, [ref])

    return (
        <div ref={ref} className="pt-4">
            <StickyContainer>
                <Container>
                    <Typography className="mt-0 mb-3" variant="h1">
                        Shopping Cart
                    </Typography>
                    <p className='mb-0 mb-lg-4'>Please review your purchases and continue</p>
                </Container>

                <Sticky>
                {({ style, isSticky }) => (
                    <div
                        id="cart-sticky-header"
                        className={cx(styles.sticky, isSticky && styles.isSticky)}
                        style={{
                            ...style,
                            top: scrollingUp ? headerOffsetTop : style.top
                        }}
                    />
                )}
                </Sticky>

                <Container>
                    <Row className="mb-5">
                        <Col md={12} lg={4}>
                            <ContinueCheckout headerPortalId='sticky-checkout-portal' />

                            <Guarantee className="d-none d-lg-block" />
                        </Col>
                        <Col md={12} lg={8} className={cx('order-lg-first', styles.itemsContainer)}>
                            {!cartItems.length &&
                            <div className={styles.emptyBox}>
                                <Typography className="mt-0" variant="h2" color="primary">
                                    Your cart is currently empty
                                </Typography>
                                <Link className={cx('btn btn-primary btn-lg')} to={destinationsUrl}>
                                    Explore some destinations
                                </Link>
                            </div>
                            }
                            {map(cartItems, (item, key) => (
                                <CartItem key={key} {...item} />
                            ))}
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col>
                            <div className={styles.card}>
                                <Link className="d-inline-flex align-items-center font-weight-bold" to="/">
                                    <Icon className="mr-2" fontSize="small">
                                        <FaArrowLeft />
                                    </Icon>
                                    Continue Shopping
                                </Link>
                                <Guarantee className="d-lg-none" />
                                <hr/>
                            </div>
                        </Col>
                    </Row> */}
                </Container>

                {/* {!!promoted_tours.length &&
                <div className={cx(styles.promoted, 'pt-4 pb-4')}>
                    <Container>
                        <Typography className="mt-3" variant="h2" color="brand-color">
                            Customers Who Bought This Tour Also Bought
                        </Typography>

                        <TourCards>
                        {map(promoted_tours, (tour, idx) => (
                            <TourCard
                                key={idx}
                                {...tour}
                            />
                        ))}
                        </TourCards>
                    </Container>
                </div>
                } */}
            </StickyContainer>
        </div>
    )
}

export default CartTemplate

import React                from 'react'
import ReactDOM             from 'react-dom'
import { map }              from 'lodash-es'
import cx                   from 'utils/classnames'
import {
    FaClock,
    FaCcVisa,
    FaCcMastercard,
    FaQuestionCircle
}                           from 'react-icons/fa'
import {
    Button,
    Container,
    Col,
    Row
}                           from 'react-bootstrap'
import { usePortal }        from 'entrada-ui/utils'
import Icon                 from 'entrada-ui/Icon'
import Typography           from 'entrada-ui/Typography'
import CartHandler          from 'components/CartHandler'
import Link                 from 'components/Link'
import styles               from './styles.module.scss'

const Header = React.forwardRef(function Header({ className, total, itemsCount }, ref) {
    return (
        <div ref={ref} className={cx(styles.header, className)}>
            <Container className="pb-3">
                <Row>
                    <Col>
                        <Typography className={cx(styles.cardTitle, "mt-0")} variant="h2">
                            Cart Total ({itemsCount})
                        </Typography>
                    </Col>
                    <Col className="text-right">
                        <Typography className={cx(styles.cardTitle, "mt-0 mb-2")} variant="h2">
                            NZ${total}
                        </Typography>
                        <p>No Additional taxes & fees</p>
                    </Col>
                </Row>

                <Link className={cx('btn btn-primary btn-block btn-lg', styles.submit, !itemsCount && 'disabled')} to={itemsCount ? "/checkout" : undefined}>
                    Continue
                </Link>
            </Container>
        </div>
    )
})

let prevScroll = 0

const ContinueCheckout = (props) => {
    const ref = React.useRef(null)
    const [headerOffsetTop, setHeaderOffsetTop] = React.useState(0)
    const [scrollingUp, setScrollingUp] = React.useState(false)
    const portalTarget = usePortal('cart-sticky-header')

    const context = React.useContext(CartHandler.Context)
    const { cartItems, cartTotal } = context

    React.useEffect(() => {
        const handleScrollWindow = (ev) => {
            const newScroll = window.pageYOffset
            setScrollingUp(prevScroll < newScroll)

            prevScroll = newScroll
        }

        window.addEventListener('scroll', handleScrollWindow)

        return () => window.removeEventListener('scroll', handleScrollWindow)
    }, [headerOffsetTop])

    React.useEffect(() => {
        if(ref) {
            setHeaderOffsetTop(ref.current.getBoundingClientRect().top)
        }
    }, [ref])

    return (
        <div ref={ref} className="mb-3">
            <Header className="d-none d-lg-block" total={cartTotal.toFixed(2)} itemsCount={cartItems.length} />

            {portalTarget &&
            ReactDOM.createPortal(
                <Header className="d-lg-none" total={cartTotal.toFixed(2)} itemsCount={cartItems.length} />,
                portalTarget
            )}

            <div className="mt-4 text-center">
                <Icon className={cx(styles.ccIcon, "mr-1")} fontSize="large">
                    <FaCcVisa />
                </Icon>
                <Icon className={styles.ccIcon} fontSize="large">
                    <FaCcMastercard />
                </Icon>
            </div>
        </div>
    )
}

ContinueCheckout.Header = Header

export default ContinueCheckout
